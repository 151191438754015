import { get } from '@/infra/rest';
import { updateAgentSessionStep } from '@/modules/sessions/requests';
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
} from '@/modules/sessions/types';
import { getSignedUrl } from '@/shared/requests/get-signed-url';
import { uploadFileReq } from '@/shared/requests/upload-file';
import { getAgentStateActions, getAgentStepData } from '../states';
import {
  Control,
  getCustomizeControls,
  getCustomizeControlsActions,
} from '../states/cusomize-controls';

export const handleContinueCustomizeControls = async (
  steps: AgentSessionStep[]
) => {
  const customizeControlStep = steps.find(
    (step) => step.type === AgentSessionStepType.CONTROL_SELECTION
  );

  if (
    customizeControlStep &&
    customizeControlStep.status === AgentSessionStatus.INPUT_NEEDED
  ) {
    await processFileForCustomizeControls(customizeControlStep.data.url);
  }
};

export const processFileForCustomizeControls = async (url: string) => {
  let customizeControlJson = await get<Control[]>({
    url,
    isAuthRequired: false,
  });

  customizeControlJson = customizeControlJson.map((control) => ({
    ...control,
    selected:
      control.selected !== undefined
        ? control.selected
        : control.type === 'default'
          ? true
          : false,
  }));

  const { setDataState, setSelectedControls } = getCustomizeControlsActions();

  setDataState(customizeControlJson);
  setSelectedControls(
    new Set(
      customizeControlJson
        .filter((control) =>
          control.selected !== undefined
            ? control.selected
            : control.type === 'default'
        )
        .map((control) => control.uid)
    )
  );
};

export const handleAutoSaveCustomizeControls = async (
  agentId: string,
  markAsComplete = false
) => {
  console.log('getCustomizeControls()', getCustomizeControls());
  const controls = JSON.stringify(getCustomizeControls());
  const blob = new Blob([controls], { type: 'application/json' });

  const stepData = getAgentStepData(agentId);

  const { updateAgentStepData } = getAgentStateActions();

  const customizeControlStep = stepData?.find(
    (step) => step.type === AgentSessionStepType.CONTROL_SELECTION
  );

  if (stepData && customizeControlStep) {
    const stepUrl = customizeControlStep.data.url;
    let currentStaleUrl = stepUrl;
    let markAsCompleteDone = false;

    if (!stepUrl.includes('signed_urls')) {
      const signedUrl = await getSignedUrl({
        file_names: [stepUrl],
        max_age: 86400,
      });
      const updatedStep = {
        ...customizeControlStep,
        data: {
          url: signedUrl[0],
        },
        status: markAsComplete
          ? AgentSessionStatus.COMPLETE
          : AgentSessionStatus.IN_PROGRESS,
      };
      const updatedSteps = stepData.map((step) => {
        if (step.id === updatedStep.id) {
          return updatedStep;
        }
        return step;
      });
      updateAgentStepData(agentId, updatedSteps);
      await updateAgentSessionStep(updatedStep);
      currentStaleUrl = signedUrl[0];
      markAsCompleteDone = true;
    }

    const expiryDate = new URL(currentStaleUrl).searchParams.get('se');
    if (expiryDate) {
      const expiry = new Date(expiryDate);
      const currentTime = new Date();
      const diff = expiry.getTime() - currentTime.getTime();
      if (diff < 0) {
        const signedUrl = await getSignedUrl({
          stale_urls: [currentStaleUrl],
          max_age: 86400,
        });
        currentStaleUrl = signedUrl[0];
      }
    }
    await uploadFileReq(currentStaleUrl, blob);

    if (markAsComplete && !markAsCompleteDone) {
      const updatedStep = {
        ...customizeControlStep,
        data: {
          url: currentStaleUrl,
        },
        status: AgentSessionStatus.COMPLETE,
      };
      await updateAgentSessionStep(updatedStep);
      const updatedStepData = stepData.map((step) => {
        if (step.id === updatedStep.id) {
          return updatedStep;
        }
        return step;
      });
      updateAgentStepData(agentId, updatedStepData);
    }
  }
};
