import { AgentSessionStep } from '@/modules/sessions/types';
import { addNotification } from '@/shared/states/notification';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { mutative } from 'zustand-mutative';
import {
  AGENT_TYPES,
  AgentData,
  AgentSpecificReviewResponseType,
  AgentSpecificSubTypes,
  AgentState,
  FilterOption,
  ReviewResponseData,
  ReviewResponseTableRow,
  RiskAssessmentTypes,
} from '../types';
import {
  QuestionnaireActions,
  createQuestionnaireActions,
} from './questionnaire';
import { approveAll, setNextUnapproved, updateApprovedIds } from './utils';

const initialState: AgentState = {
  agentDataMap: new Map(),
  agentLogBaseData: [],
};

type ApprovedArgs =
  | 'all'
  | {
      id: string | string[];
      isApproved: boolean;
      setNext?: boolean;
      showNotification?: boolean;
    };

type NavigateArgs = {
  agentId: string;
  currentId: string;
  to: 'next' | 'previous';
};

type AgentActions = {
  setAgentState: (state: Partial<AgentState>) => void;
  setAgentData: (
    agentId: string,
    data: AgentData<AGENT_TYPES, AgentSpecificSubTypes<AGENT_TYPES>>
  ) => void;
  updateAgentData<T extends AGENT_TYPES, S extends AgentSpecificSubTypes<T>>(
    agentId: string,
    data: Partial<AgentData<T, S>>
  ): void;
  updateAgentSessionData<
    T extends AGENT_TYPES,
    S extends AgentSpecificSubTypes<T>,
  >(
    agentId: string,
    sessionData: Partial<AgentData<T, S>['sessionData']>
  ): void;

  updateAgentStepData(agentId: string, stepData: AgentSessionStep[]): void;

  updateAgentMainData<
    T extends AGENT_TYPES,
    S extends AgentSpecificSubTypes<T>,
  >(
    agentId: string,
    mainData: Partial<AgentData<T, S>['mainData']>
  ): void;
  setSelectedId: (agentId: string, id: string) => void;
  setSearchTerm: (agentId: string, term: string) => void;
  setApproved: (agentId: string, args: ApprovedArgs) => void;
  setEdited: (
    agentId: string,
    id: string | string[],
    isEdited: boolean
  ) => void;
  updateReviewResponse: <
    T extends AGENT_TYPES,
    S extends AgentSpecificSubTypes<T>,
  >(
    agentId: string,
    id: string,
    data: Pick<
      ReviewResponseData<keyof AgentSpecificReviewResponseType<T, S>>,
      'key' | 'value'
    >
  ) => void;
  updateTableRows: (
    agentId: string,
    rowId: string,
    key: keyof ReviewResponseTableRow,
    value: string
  ) => void;
  navigateRow: (args: NavigateArgs) => void;
  setStaleUrl: (agentId: string, url: string) => void;
  setFinalFileUrl: (agentId: string, url: string) => void;
  setFilters: (agentId: string, filters: FilterOption[]) => void;
};

export type AgentStore = AgentState & {
  actions: AgentActions & QuestionnaireActions;
};

export const useAgentStore = create<AgentStore>()(
  mutative((set, get) => ({
    ...initialState,
    actions: {
      setAgentState: (state) => {
        set((draft) => {
          Object.assign(draft, state);
        });
      },
      setAgentData: (agentId, data) => {
        set((draft) => {
          draft.agentDataMap.set(agentId, data);
        });
      },
      updateAgentData: (agentId, data) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            Object.assign(agentData, data);
          }
        });
      },
      updateAgentSessionData: (agentId, sessionData) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            Object.assign(agentData.sessionData, sessionData);
          }
        });
      },
      updateAgentStepData: (agentId, stepData) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            agentData.stepData = stepData;
          }
        });
      },
      updateAgentMainData: (agentId, mainData) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            Object.assign(agentData.mainData, mainData);
          }
        });
      },
      setSelectedId: (agentId, id) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            agentData.mainData.selectedId = id;
          }
        });
      },
      setSearchTerm: (agentId, term) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            agentData.mainData.searchTerm = term;
          }
        });
      },
      setApproved: (agentId, args) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (!agentData) return;

          if (args === 'all') {
            approveAll(agentData);
            addNotification({ message: 'All responses approved successfully' });
            return;
          }

          const { id, isApproved, setNext, showNotification = true } = args;

          updateApprovedIds(agentData, id, isApproved);

          let setNextSuccess = undefined;
          if (setNext) {
            setNextSuccess = setNextUnapproved(agentData);
          }

          if (!showNotification) return;

          let title = 'Response accepted';
          let message = 'Zania will auto-fill this in the final report';

          if (typeof id !== 'string') {
            title = `${id.length} responses accepted`;
            message = 'Zania will auto-fill these in the final report';
          }
          if (setNextSuccess === true) {
            message = 'Loading next response...';
          }

          addNotification({ title, message });
        });
      },
      setEdited(agentId, id, isEdited) {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            if (Array.isArray(id)) {
              if (isEdited) {
                agentData.mainData.editedIds.push(...id);
              } else {
                agentData.mainData.editedIds =
                  agentData.mainData.editedIds.filter(
                    (editedId) => !id.includes(editedId)
                  );
              }
            } else {
              if (isEdited) {
                agentData.mainData.editedIds.push(id);
              } else {
                agentData.mainData.editedIds =
                  agentData.mainData.editedIds.filter(
                    (editedId) => editedId !== id
                  );
              }
            }
          }
        });
      },
      updateReviewResponse: (agentId, id, data) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (!agentData) return;

          const reviewResponseData =
            agentData.mainData.reviewResponseData?.get(id);
          if (!reviewResponseData) return;

          const idx = reviewResponseData.findIndex(
            (item) => item.key === data.key
          );
          if (idx === -1) return;

          (reviewResponseData[idx] as { value: unknown }).value = data.value;

          const approvedIds = agentData.mainData.approvedIds;
          if (approvedIds.includes(id)) {
            approvedIds.splice(approvedIds.indexOf(id), 1);
            addNotification({
              message:
                'The response you accepted has been edited. Accept the revised response again.',
              title: 'Response Status Changed',
              type: 'warn',
            });
          }

          const editedIds = agentData.mainData.editedIds;

          if (editedIds && !editedIds.includes(id)) {
            editedIds.push(id);
          }
        });
      },
      updateTableRows: (agentId, rowId, key, value) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData && agentData.mainData.tableRows) {
            const tableRows = agentData.mainData.tableRows;
            if (!tableRows) return;

            const rowIndex = tableRows.findIndex((row) => row.id === rowId);
            if (rowIndex !== -1) {
              agentData.mainData.tableRows[rowIndex] = {
                ...tableRows[rowIndex],
                [key]: value,
              };
            }
          }
        });
      },
      navigateRow: ({ agentId, currentId, to }) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          const ids = agentData?.mainData?.reviewResponseIds;
          if (!ids?.length || !agentData) return;

          const currentIndex = ids.indexOf(currentId);
          if (currentIndex === -1) return;

          const newIndex =
            to === 'next'
              ? (currentIndex + 1) % ids.length
              : (currentIndex - 1 + ids.length) % ids.length;

          agentData.mainData.selectedId = ids[newIndex];
        });
      },
      setStaleUrl: (agentId, url) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            agentData.mainData.staleUrl = url;
          }
        });
      },
      setFinalFileUrl: (agentId, url) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            agentData.mainData.finalFileUrl = url;
          }
        });
      },
      setFilters: (agentId, filters) => {
        set((draft) => {
          const agentData = draft.agentDataMap.get(agentId);
          if (agentData) {
            agentData.mainData.filters = filters;
          }
        });
      },
      ...createQuestionnaireActions(set, get),
    },
  }))
);

export const useAgentSelector = createSelectorHooks(useAgentStore);

export const getAgentStateActions = () => useAgentStore.getState().actions;

export const getAgentState = () => useAgentStore.getState();

export const getAgentTypeFromId = (id: string) => {
  const agentData = useAgentStore.getState().agentDataMap.get(id);
  if (!agentData) {
    return {
      agentType: AGENT_TYPES.RISK_ASSESSMENT,
      agentSubType: RiskAssessmentTypes.NIST_CSF_2,
    };
  }

  return {
    agentType: agentData.agentType,
    agentSubType: agentData.subType,
  };
};

export const getAgentData = <
  T extends AGENT_TYPES,
  S extends AgentSpecificSubTypes<T>,
>(
  id: string
): AgentData<T, S> | undefined => {
  const agentData = useAgentStore.getState().agentDataMap.get(id);
  return agentData as AgentData<T, S> | undefined;
};

//CUSTOM SELECTOR HOOKS
export const useAgentData = (id: string) =>
  useAgentStore((state) => state.agentDataMap.get(id));

export const useAgentStepData = (id: string) =>
  useAgentStore((state) => state.agentDataMap.get(id)?.stepData);

export const getAgentStepData = (id:string) => useAgentStore.getState().agentDataMap.get(id)?.stepData

export const useAgentSessionData = (id: string) =>
  useAgentStore((state) => state.agentDataMap.get(id)?.sessionData);

export const useAgentMainData = (id: string) =>
  useAgentStore((state) => state.agentDataMap.get(id)?.mainData);

export const useReviewResponse = (id: string) =>
  useAgentStore((state) => ({
    ids: state.agentDataMap.get(id)?.mainData.reviewResponseIds,
    reviewResponse: state.agentDataMap.get(id)?.mainData.reviewResponseData,
  }));

export const useReviewResponseIds = (id: string) =>
  useAgentStore(
    (state) => state.agentDataMap.get(id)?.mainData.reviewResponseIds ?? []
  );

export const useReviewResponseFilters = (id: string) =>
  useAgentStore((state) => state.agentDataMap.get(id)?.mainData.filters ?? []);

export const useApprovedIds = (id: string) =>
  useAgentStore(
    (state) => state.agentDataMap.get(id)?.mainData.approvedIds ?? []
  );

export const useEditedIds = (id: string) =>
  useAgentStore(
    (state) => state.agentDataMap.get(id)?.mainData.editedIds ?? []
  );

export const useTableRows = (id: string) =>
  useAgentStore((state) => state.agentDataMap.get(id)?.mainData.tableRows);

export const useSelectedId = (id: string) =>
  useAgentStore((state) => state.agentDataMap.get(id)?.mainData.selectedId);

export const useSelectedReviewResponse = (id: string) =>
  useAgentStore((state) => ({
    id: state.agentDataMap.get(id)?.mainData.selectedId,
    data: state.agentDataMap
      .get(id)
      ?.mainData.reviewResponseData?.get(
        state.agentDataMap.get(id)?.mainData.selectedId ?? ''
      ),
  }));

export const useReviewResponseSearch = (id: string) =>
  useAgentStore((state) => ({
    searchTerm: state.agentDataMap.get(id)?.mainData.searchTerm,
    searchFor: state.agentDataMap.get(id)?.mainData.searchFor,
  }));
