export const checkOrganizationName = (
  organization: string,
  emailSlug: string
) => {
  return organization.toLowerCase().includes(emailSlug.toLowerCase());
};

export const getEmailCompanySlug = (email: string) => {
  const emailParts = email.split('@');
  return emailParts[1].split('.')[0];
};

// Below code is used to check the valid user Domain
export const checkValidDomain = (
  email_address: string,
  emailAllowedDomain: Array<string>
) => {
  const userCurrentDomain =
    email_address.split('@')[email_address.split('@').length - 1];
  return [...emailAllowedDomain, 'zania.ai', 'gmail.com'].includes(
    userCurrentDomain.toLowerCase()
  );
};

export const generateOrgSlug = (organization: string) => {
  return organization.split(' ').join('-').toLowerCase();
};
