import { SettingsRoutes } from '@/modules/settings/routes';
import { TeamRoutes } from '@/modules/team/routes';
import { AgentSessionRoutes } from '@/modules/sessions/routes';
import { ROUTES } from '@/shared/constants/routes';
import { importLazy } from '@/shared/utils/import-lazy';
import { Navigate, RouteObject } from 'react-router-dom';
import { AgentRoutes } from '@/modules/agent/routes';

export const AppUnprotectedRoutes: RouteObject[] = [
  {
    path: ROUTES.AUTH,
    lazy: importLazy(
      () => import('@/modules/auth/components/authenticate-token')
    ),
  },
  {
    path: ROUTES.LOGIN,
    lazy: importLazy(() => import('@/modules/auth/components/login')),
  },
  {
    path: ROUTES.SIGNUP,
    lazy: importLazy(() => import('@/modules/auth/components/sign-up')),
  },
  {
    path: '*',
    element: <Navigate to={ROUTES.LOGIN} replace={true} />,
  },
];

export const MainRoutes: RouteObject[] = [
  ...AgentRoutes,
  ...SettingsRoutes,
  ...TeamRoutes,
  ...AgentSessionRoutes,
  // {
  //   path: ROUTES.LOGIN,
  //   lazy: importLazy(() => import('@/modules/auth/components/login')),
  // },
  // {
  //   path: ROUTES.AUTH,
  //   lazy: importLazy(
  //     () => import('@/modules/auth/components/authenticate-token')
  //   ),
  // },
  {
    path: '*',
    element: <Navigate to={ROUTES.AGENT} replace={true} />,
  },
];

export const AppProtectedRoutes: RouteObject[] = [
  {
    path: '/*',
    lazy: importLazy(() => import('../components/layout')),
    children: [...MainRoutes],
  },
];
